<template>
  <div class="row">
    <EmptyState
      v-if="showBlankState"
      icon="jbsmd-coins"
      :message="$gettext('You don\'t have any invoices')"
      :show-button="false"
    ></EmptyState>

    <div v-show="!showBlankState" class="col-md-12">
      <ContentHeader :title="$gettext('Billing history')" />

      <div class="card">
        <div class="card-body">
          <b-table :fields="fields" :items="items">
            <template #cell(created_at)="{item}">
              <FormattedDate :date="item.created_at * 1000" />
            </template>
            <template #cell(total)="data"> {{ data.item.total }} $ </template>
            <template #cell(actions)="data">
              <b-button
                @click="handleDownload(data)"
                variant="info"
                class="btn btn-info btn-icon btn-sm"
                v-b-tooltip.hover
                :title="$gettext('Download')"
                ><i class="fa fa-download"></i
              ></b-button>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import EmptyState from "@/components/EmptyState";
import ContentHeader from "@/components/ContentHeader";
import FormattedDate from "@/components/FormattedDate";
import { VBTooltip } from "bootstrap-vue";
Vue.directive("b-tooltip", VBTooltip);
export default {
  components: { FormattedDate, EmptyState, ContentHeader },
  created() {
    this.fetch().finally(this.stopFetching);
  },
  computed: {
    ...mapGetters("invoices", ["items", "showBlankState"]),
    fields() {
      return [
        { key: "number", label: this.$gettext("Number") },
        { key: "status", label: this.$gettext("Status") },
        { key: "total", label: this.$gettext("Total") },
        { key: "created_at", label: this.$gettext("Date") },
        {
          key: "actions",
          tdClass: "td-action-2",
          label: this.$gettext("Actions")
        }
      ];
    }
  },
  methods: {
    ...mapActions("app", ["stopFetching"]),
    ...mapActions("invoices", ["fetch"]),
    handleDownload({ item }) {
      window.open(item.invoice_pdf, "_blank");
    }
  }
};
</script>
